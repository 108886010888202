import a1 from "../media/1941 17' Chris Craft/1.jpeg";
import a2 from "../media/1941 17' Chris Craft/2.jpeg";
import a3 from "../media/1941 17' Chris Craft/3.jpeg";
import a4 from "../media/1941 17' Chris Craft/4.jpeg";
import a5 from "../media/1941 17' Chris Craft/5.jpeg";
import a6 from "../media/1941 17' Chris Craft/6.jpeg";
import a7 from "../media/1941 17' Chris Craft/7.jpeg";
import a8 from "../media/1941 17' Chris Craft/8.jpeg";
import a9 from "../media/1941 17' Chris Craft/9.jpeg";
import b1 from "../media/1948 Chris Craft Sedan/1.jpeg";
import b2 from "../media/1948 Chris Craft Sedan/2.jpeg";
import b3 from "../media/1948 Chris Craft Sedan/3.jpeg";
import b4 from "../media/1948 Chris Craft Sedan/4.jpeg";
import b5 from "../media/1948 Chris Craft Sedan/5.jpeg";
import b6 from "../media/1948 Chris Craft Sedan/6.jpeg";
import c1 from "../media/1956 Century Coronado/1.jpeg";
import c2 from "../media/1956 Century Coronado/2.jpeg";
import c3 from "../media/1956 Century Coronado/3.jpeg";
import c4 from "../media/1956 Century Coronado/4.jpeg";
import c5 from "../media/1956 Century Coronado/5.jpeg";
import c6 from "../media/1956 Century Coronado/6.jpeg";
import c7 from "../media/1956 Century Coronado/7.jpeg";
import c8 from "../media/1956 Century Coronado/8.jpeg";
import c9 from "../media/1956 Century Coronado/9.jpeg";
import c10 from "../media/1956 Century Coronado/10.jpeg";
import d1 from "../media/1966 Chris Craft Super Sport/1.jpeg";
import d2 from "../media/1966 Chris Craft Super Sport/2.jpeg";
import d3 from "../media/1966 Chris Craft Super Sport/3.jpeg";
import d4 from "../media/1966 Chris Craft Super Sport/4.jpeg";
import d5 from "../media/1966 Chris Craft Super Sport/5.jpeg";
import d6 from "../media/1966 Chris Craft Super Sport/6.jpeg";
import d7 from "../media/1966 Chris Craft Super Sport/7.jpeg";
import d8 from "../media/1966 Chris Craft Super Sport/8.jpeg";
import d9 from "../media/1966 Chris Craft Super Sport/9.jpeg";
import e1 from "../media/1980's Kit Boat/1.jpeg";
import e2 from "../media/1980's Kit Boat/2.jpeg";
import e3 from "../media/1980's Kit Boat/3.jpeg";
import e4 from "../media/1980's Kit Boat/4.jpeg";
import e5 from "../media/1980's Kit Boat/5.jpeg";
import e6 from "../media/1980's Kit Boat/6.jpeg";
import e7 from "../media/1980's Kit Boat/7.jpeg";
import f1 from "../media/Chris Craft Rivera/1.jpeg";
import f2 from "../media/Chris Craft Rivera/2.jpeg";
import f3 from "../media/Chris Craft Rivera/3.jpeg";
import f4 from "../media/Chris Craft Rivera/4.jpeg";
import f5 from "../media/Chris Craft Rivera/5.jpeg";
import f6 from "../media/Chris Craft Rivera/6.jpeg";
import f7 from "../media/Chris Craft Rivera/7.jpeg";
import f8 from "../media/Chris Craft Rivera/8.jpeg";
import f9 from "../media/Chris Craft Rivera/9.jpeg";
import f10 from "../media/Chris Craft Rivera/10.jpeg";
import f11 from "../media/Chris Craft Rivera/11.jpeg";
import f12 from "../media/Chris Craft Rivera/12.jpeg";
import g1 from "../media/Chris Craft Sportsman/1.jpeg";
import g2 from "../media/Chris Craft Sportsman/2.jpeg";
import g3 from "../media/Chris Craft Sportsman/3.jpeg";
import g4 from "../media/Chris Craft Sportsman/4.jpeg";
import g5 from "../media/Chris Craft Sportsman/5.jpeg";
import g6 from "../media/Chris Craft Sportsman/6.jpeg";
import g7 from "../media/Chris Craft Sportsman/7.jpeg";
import g8 from "../media/Chris Craft Sportsman/8.jpeg";
import g9 from "../media/Chris Craft Sportsman/9.jpeg";
import g10 from "../media/Chris Craft Sportsman/10.jpeg";
import g11 from "../media/Chris Craft Sportsman/11.jpeg";
import g12 from "../media/Chris Craft Sportsman/12.jpeg";
import g13 from "../media/Chris Craft Sportsman/13.jpeg";
import g14 from "../media/Chris Craft Sportsman/14.jpeg";

const a = [a1,a2,a3,a4,a5,a6,a7,a8,a9];
const b = [b1,b2,b3,b4,b5,b6];
const c = [c1,c2,c3,c4,c5,c6,c7,c8,c9,c10];
const d = [d1,d2,d3,d4,d5,d6,d7,d8,d9];
const e = [e1,e2,e3,e4,e5,e6,e7];
const f = [f1,f2,f3,f4,f5,f6,f7,f8,f9,f10,f11,f12];
const g = [g1,g2,g3,g4,g5,g6,g7,g8,g9,g10,g11,g12,g13,g14];

export {a,b,c,d,e,f,g}